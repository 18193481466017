"use client";

import { ConnectedRouter } from "connected-react-router";
import Cookie from "js-cookie";
import mixpanel from "mixpanel-browser";
import { env } from "next-runtime-env";
import { useEffect } from "react";
import { Router } from "react-router-dom";

import { setLogLevel } from "@skydio/skybus/src/logger";

import { IS_DEV } from "app/env";
import { history } from "state/store";
import ThemeProvider from "styles/theme";

import type { ReactNode } from "react";

import "i18n";

/** Client layout component for browser router */
const Layout = ({ children }: { children: ReactNode }) => {
  // Set up mixpanel
  useEffect(() => {
    mixpanel.init(env("NEXT_PUBLIC_MIXPANEL_TOKEN") ?? "test token", {
      // avoid issue of extra large cookie preventing requests from going through:
      // https://help.mixpanel.com/hc/en-us/articles/115004546863-Mixpanel-Cookie-Too-Large
      persistence: "localStorage",
      // check for cookies from old library versions and upgrade / remove them
      upgrade: true,
      api_host: env("NEXT_PUBLIC_BACKEND_URL") + "/mp",
    });
  }, []);

  // Clear any legacy mixpanel cookies that may be left behind from using the customer portal
  // across different domains, since those aren't auto-cleared by the mixpanel library
  useEffect(() => {
    Object.keys(Cookie.get()).forEach(cookie => {
      if (cookie.startsWith("mp_")) {
        console.log("Removing legacy mixpanel cookie", cookie);
        Cookie.remove(cookie, { path: "/", domain: ".skydio.com" });
      }
    });
  }, []);

  // Enable skybus debug logging in development
  useEffect(() => {
    if (IS_DEV) {
      setLogLevel("debug");
    }
  }, []);

  return (
    <Router history={history}>
      <ConnectedRouter history={history}>
        <ThemeProvider>{children}</ThemeProvider>
      </ConnectedRouter>
    </Router>
  );
};

export default Layout;
